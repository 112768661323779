'use strict';	

//Global
var _jg = _jg || {};
_jg.widget = _jg.widget || {};

_jg.widget.init = function ( widgetName ) {
	_jg.widget[widgetName] = _jg.widget[widgetName] || {};
	_jg.widget[widgetName].widgetName = widgetName;
	_jg.widget.contentLoaded( window, function() {
		_jg.widget.makeWidget( widgetName );
	});
};
_jg.widget.contentLoaded = function ( win, fn ) {
	var done = false, top = true,
	doc = win.document,
	root = doc.documentElement,
	modern = doc.addEventListener,
	add = modern ? 'addEventListener' : 'attachEvent',
	rem = modern ? 'removeEventListener' : 'detachEvent',
	pre = modern ? '' : 'on',
	init = function(e) {
		if (e.type === 'readystatechange' && doc.readyState !== 'complete') { 
			return;
		}
		(e.type === 'load' ? win : doc)[rem](pre + e.type, init, false);
		if (!done && (done = true)) { 
			fn.call(win, e.type || e);
		}	
	},
	poll = function() {
		try { root.doScroll('left'); } catch(e) { setTimeout(poll, 50); return; }
		init('poll');
	};
	if (doc.readyState === 'complete') { 
		fn.call(win, 'lazy'); 
	} else {
		if (!modern && root.doScroll) {
			try { top = !win.frameElement; } catch(e) { }
			if (top) { 
				poll();
			}	
		}
		doc[add](pre + 'DOMContentLoaded', init, false);
		doc[add](pre + 'readystatechange', init, false);
		win[add](pre + 'load', init, false);
	}
};	
_jg.widget.makeWidget = function ( widgetName ) {
	_jg.widget[widgetName].widget = new _jg.widget.Host({
		widgetName: widgetName
	});
};

/*++++++++++++++++++++++++
+ Host
+++++++++++++++++++++++++*/
_jg.widget.Host = function ( options ) {
	if ( !options ) {
		return;
	}
	//Kill widgets in IE8 or below
	if ( !('querySelector' in document && 'localStorage' in window && 'addEventListener' in window) ) {
		return;
    }
    this.widgetName = options.widgetName;
	this.init();
};
/*++++++++++++++++++++++++
+ Initialize
+++++++++++++++++++++++++*/
_jg.widget.Host.prototype.init = function () {
	this.widgetContainer = document.getElementById('jg-' + this.widgetName);
	var widgetHostName = this.getWidgetHostName();
	this.widgetBaseUrl = 'https://' + widgetHostName;
	this.widgetUrl = this.widgetBaseUrl + '/' + this.widgetName + '/'; 
	
	if ( !this.widgetContainer ) {
			return;
		}
		this.buildWidget();
		this.bindListeners();
};
_jg.widget.Host.prototype.bindListeners = function () {
	var self = this;
	if ( window.addEventListener ) {
	    window.addEventListener('message', function( e ) {
	    	self.delegateMessage.call(self, e);
	    });	
	} else {
	    window.attachEvent('onmessage',  function( e ) {
	    	self.delegateMessage.call(self, e);
	    });	
	}
};

/*++++++++++++++++++++++++
+ Incoming messages
+++++++++++++++++++++++++*/
_jg.widget.Host.prototype.delegateMessage = function ( e ) {
	var message = this.parseMessage( e );
		if ( !message ) {
			return;
		}
		var key = message[0];
		var value = message[1];

	switch ( key ) {
		case 'contentHeight':
			this.setWidgetHeight( value );
		break;
		default:
		break;
	}	
};
_jg.widget.Host.prototype.parseMessage = function ( e ) {
	var origin = this.removeTrailingSlash( e.origin );
	var message = e.data;
	var messageHash = {};
	//Hashify the message
	message.split('&').forEach(function( msg ) {
		var pair = msg.split('=');
 		messageHash[pair[0]] = pair[1]; 
	});
	//check if our message hash has relevant data
	if ( !messageHash.widgetName || !messageHash.contentHeight ) {
		return;
	}
	//Only continue if the message is from the expected domain and the same widget type as this widget instance
	var expectedOrigin = this.removeTrailingSlash( this.widgetBaseUrl );
	var postingWidget = messageHash.widgetName;
	if ( postingWidget !== this.widgetName || origin !== expectedOrigin ) {
		return;
	}
	return ['contentHeight', messageHash.contentHeight];
};

/*++++++++++++++++++++++++
+ Build widget
+++++++++++++++++++++++++*/
_jg.widget.Host.prototype.getWidgetHostName = function () {
	var scripts = document.getElementsByTagName('script');
    var hostName ='widgets.justgiving.com';
    var script; 
    if( scripts && scripts.length > 0 ) {
        for ( var i = 0; i < scripts.length; i++ ) {
    		script = scripts[i];
    		if ( script.src && script.src.split('/').pop() === 'host.js' && script.src.indexOf('justgiving') > -1 ) {
    			if ( script.src.indexOf('://') > -1 ) {
        			hostName = script.src.split('/')[2];
    			} else {
			        hostName = script.src.split('/')[0];
			    }
			    break;
    		}
        }
        hostName = hostName.replace('8080', '8181');
        return hostName;
    }
};
_jg.widget.Host.prototype.getWidgetConfig = function () {
	return this.widgetContainer.getAttribute('data-config') && 
		this.widgetContainer
			.getAttribute('data-config')
			.replace(/:/gi, '=')
			.replace(/,/gi, '&')
			.trim();
};
_jg.widget.Host.prototype.buildWidget = function () {
	var config = this.getWidgetConfig();
	this.widget = document.createElement('iframe');
	this.widget.src = config ? this.widgetUrl + '?' + config + '&parentReferrer=' + document.referrer : 
		this.widgetUrl + '?parentReferrer=' + document.referrer;
	this.widget.frameBorder = 0;
	this.widget.style.border = 0;
	this.widget.style.width = '100%';
	this.widget.style.height = 0;
	this.widgetContainer.appendChild( this.widget );
};

/*++++++++++++++++++++++++
+ Message handlers
+++++++++++++++++++++++++*/
_jg.widget.Host.prototype.setWidgetHeight = function( height ) {
	this.widget.style.height = '100%';
	this.widgetContainer.style.height = height + 'px';
};

/*++++++++++++++++++++++++
+ Utils
+++++++++++++++++++++++++*/
_jg.widget.Host.prototype.removeTrailingSlash = function ( str ) {
	return str.charAt(str.length - 1) === '/' ? str.replace(/\/$/, '') : str;
};
'use strict';	

window._jg.widget.init( 'fundraiser-leaderboard' );
